var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"base-div",class:_vm.color},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('NewToolbar',{attrs:{"rule-button":Boolean(
        _vm.$route.name === 'timelineHome' ||
          (_vm.$route.name === 'roundTableItems' && _vm.debate?.showDebate) ||
          (_vm.$route.name === 'communityMembersHome' && _vm.community.id) ||
          (_vm.$route.name === 'communityHome' && _vm.community.id)
      )},on:{"sidebar":_vm.updateToggleSidebar,"filter":_vm.updateToggleFilter}}):_vm._e(),_c('NewSidebar',{attrs:{"toggle":_vm.toggleSidebar,"is-notification":_vm.isNotification,"is-message-notification":_vm.isMessageNotification}}),(
      _vm.$route.name === 'timelineHome' ||
      (_vm.$route.name === 'roundTableItems' && _vm.debate?.showDebate) ||
      (_vm.$route.name === 'communityMembersHome' && _vm.community.id) ||
      (_vm.$route.name === 'communityHome' && _vm.community.id)
    )?_c('SidebarFilter',{attrs:{"debate":_vm.debate,"community":_vm.community,"disabled":_vm.disabled,"current-loading-loungeId":_vm.currentLoadingLoungeId,"toggle":_vm.toggleFilter},on:{"leave":function($event){return _vm.$emit('leave', _vm.community.id)},"openModalUser":function($event){return _vm.$emit('openModalUser')},"edit":function($event){return _vm.$emit('edit')},"delete":function($event){return _vm.$emit('delete', _vm.community.id)}}}):_vm._e(),_c('v-main',{class:_vm.color},[_c('TheAlert',{on:{"close":_vm.updateAlertNotificationStatus},model:{value:(_vm.alertNotification),callback:function ($$v) {_vm.alertNotification=$$v},expression:"alertNotification"}}),_c('v-container',{class:_vm.padding,attrs:{"app":"","fluid":""}},[_vm._t("default")],2)],1),_c('LoadingModal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }