import {
  differenceInCalendarDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  differenceInHours,
  differenceInMinutes,
  format,
  formatISO,
} from "date-fns";
import i18n from "@/plugins/i18n";
import pt from "date-fns/locale/pt-BR";

export const formatTodayIso = (): string => {
  return formatISO(new Date());
};

export const formatDatePost = (date: string): string => {
  const diffDays = differenceInCalendarDays(new Date(), new Date(date));
  const formatToday =
    i18n.locale === "pt"
      ? format(new Date(date), "'Hoje às 'HH:mm")
      : i18n.locale === "en"
      ? format(new Date(date), "'Today at 'HH:mm")
      : format(new Date(date), "'Hoy a las 'HH:mm");
  const formatYesterday =
    i18n.locale === "pt"
      ? format(new Date(date), "'Ontem às 'HH:mm")
      : i18n.locale === "en"
      ? format(new Date(date), "'Yesterday at 'HH:mm")
      : format(new Date(date), "'Ayer a las 'HH:mm");
  const formatOthersDay =
    i18n.locale === "pt"
      ? format(new Date(date), "dd/MM' às 'HH:mm")
      : i18n.locale === "en"
      ? format(new Date(date), "MM/dd' at 'HH:mm")
      : format(new Date(date), "dd/MM' a las 'HH:mm");
  return diffDays === 0
    ? formatToday
    : diffDays === 1
    ? formatYesterday
    : formatOthersDay;
};

export const formatDateLastUpdate = (date: string, sufix = ""): string => {
  const diffMins = differenceInMinutes(new Date(), new Date(date));
  const diffHours = differenceInHours(new Date(), new Date(date));
  const diffDays = differenceInCalendarDays(new Date(), new Date(date));
  const diffWeeks = differenceInWeeks(new Date(), new Date(date));
  const diffMonths = differenceInMonths(new Date(), new Date(date));
  const diffYears = differenceInYears(new Date(), new Date(date));
  return diffDays === 0
    ? diffHours === 0
      ? `${diffMins} ${
          diffMins > 1 ? i18n.t("format.minutes") : i18n.t("format.minute")
        }${sufix}`
      : `${diffHours} ${
          diffHours > 1 ? i18n.t("format.hours") : i18n.t("format.hour")
        }${sufix}`
    : diffDays === 1
    ? `${i18n.t("format.yesterday")}`
    : diffDays < 7
    ? `${diffDays} ${i18n.t("format.days")}${sufix}`
    : diffWeeks === 1
    ? `${diffWeeks} ${i18n.t("format.week")}${sufix}`
    : diffWeeks < 5
    ? `${diffWeeks} ${i18n.t("format.weeks")}${sufix}`
    : diffMonths === 1
    ? `${diffMonths} ${i18n.t("format.month")}${sufix}`
    : diffMonths < 12
    ? `${diffMonths} ${i18n.t("format.months")}${sufix}`
    : diffYears === 1
    ? `${diffYears} ${i18n.t("format.year")}${sufix}`
    : `${diffYears} ${i18n.t("format.years")}${sufix}`;
};

export const formatDayMonth = (date: string): string => {
  return i18n.locale === "en"
    ? format(new Date(date), "MMM/dd")
    : format(new Date(date), "dd/MMM", { locale: pt });
};

export const formatDayMonthYear = (date: string): string => {
  return i18n.locale === "en"
    ? format(new Date(date), "yyyy/MM/dd")
    : format(new Date(date), "dd/MM/yyyy", { locale: pt });
};

export const formatDateLastChatUpdate = (date: string): string => {
  const diffDays = differenceInCalendarDays(new Date(), new Date(date));
  return diffDays === 0
    ? `${i18n.t("misc.today")}`
    : diffDays === 1
    ? `${i18n.t("misc.yesterday")}`
    : i18n.locale === "en"
    ? format(new Date(date), "yyyy/MM/dd")
    : format(new Date(date), "dd/MM/yyyy", { locale: pt });
  // const diffMins = differenceInMinutes(new Date(), new Date(date));
  // const diffHours = differenceInHours(new Date(), new Date(date));
  // const diffDays = differenceInCalendarDays(new Date(), new Date(date));
  // const diffWeeks = differenceInWeeks(new Date(), new Date(date));
  // const diffMonths = differenceInMonths(new Date(), new Date(date));
  // const diffYears = differenceInYears(new Date(), new Date(date));
  // return diffDays === 0
  //   ? diffHours === 0
  //     ? `${i18n.t("format.prefix")} ${diffMins}min ${i18n.t("format.sufix")}`
  //     : `${i18n.t("format.prefix")} ${diffHours}h ${i18n.t("format.sufix")}`
  //   : diffDays === 1
  //   ? `${i18n.t("format.prefix")} ${i18n.t("format.yesterday")} ${i18n.t(
  //       "format.sufix"
  //     )}`
  //   : diffDays < 7
  //   ? `${i18n.t("format.prefix")} ${diffDays} ${i18n.t("format.days")} ${i18n.t(
  //       "format.sufix"
  //     )}`
  //   : diffWeeks === 1
  //   ? `${i18n.t("format.prefix")} ${diffWeeks} ${i18n.t(
  //       "format.week"
  //     )} ${i18n.t("format.sufix")}`
  //   : diffWeeks < 4
  //   ? `${i18n.t("format.prefix")} ${diffWeeks} ${i18n.t(
  //       "format.weeks"
  //     )} ${i18n.t("format.sufix")}`
  //   : diffMonths === 1
  //   ? `${i18n.t("format.prefix")} ${diffMonths} ${i18n.t(
  //       "format.month"
  //     )} ${i18n.t("format.sufix")}`
  //   : diffMonths < 12
  //   ? `${i18n.t("format.prefix")} ${diffMonths} ${i18n.t(
  //       "format.months"
  //     )} ${i18n.t("format.sufix")}`
  //   : diffYears === 1
  //   ? `${i18n.t("format.prefix")} ${diffYears} ${i18n.t(
  //       "format.year"
  //     )} ${i18n.t("format.sufix")}`
  //   : `${i18n.t("format.prefix")} ${diffYears} ${i18n.t(
  //       "format.years"
  //     )} ${i18n.t("format.sufix")}`;
};
