import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import _ from "lodash";
@Component
export class ActionMixin extends Vue {
  @Action("showAlertNotification") showAlertNotification: any;
  async withLoading(p: () => Promise<any>): Promise<void> {
    try {
      return await p();
    } catch (e: any) {
      const messageDefaultError =
        e?.message === "Network Error" || e?.response?.status === 504
          ? this.$t("misc.timeout")
          : this.$t("misc.error");
      const message =
        e.response?.status === 401
          ? this.$t("misc.expiredToken")
          : _.isString(e.response?.data)
          ? e.response?.data.length > 0
            ? e.response?.data
            : messageDefaultError
          : e.response?.data?.message || messageDefaultError;
      this.showAlertNotification({
        message: message,
        color: "redAlert",
        icon: "fa-regular fa-circle-xmark",
      });
      return Promise.reject(e);
    }
  }
}
