
import i18n from "@/plugins/i18n";
import { TranslateResult } from "vue-i18n";
import { Prop, Watch } from "vue-property-decorator";
import { Action, State } from "vuex-class";
import Component, { mixins } from "vue-class-component";
import { ActionMixin } from "../mixins/ActionMixin";
import { TokenService } from "@/api/storage";
import Hashids from "hashids";
import UserPost from "@/modules/timeline/components/UserPost.vue";
import Bowser from "bowser";
type sidebar_items = {
  imageHover: any;
  imageSelected: any;
  imageUnselected: any;
  name: string | TranslateResult;
  route: string;
};
@Component({
  components: {
    UserPost,
  },
})
export default class TheSidebar extends mixins(ActionMixin) {
  @Prop({ default: false }) toggle: boolean;
  @Prop(Boolean) isNotification: boolean;
  @Prop(Boolean) isMessageNotification: boolean;
  selected = null;
  generalItemsClevel = [
    {
      imageHover: require("@/assets/timeline_sidebar_black.svg"),
      imageSelected: require("@/assets/timeline_sidebar_purple.svg"),
      imageUnselected: require("@/assets/timeline_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/timeline_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/timeline_sidebar_white.svg"),
      name: "Timeline",
      route: "timelineHome",
    },
    {
      imageHover: require("@/assets/lounge_sidebar_black.svg"),
      imageSelected: require("@/assets/lounge_sidebar_purple.svg"),
      imageUnselected: require("@/assets/lounge_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/lounge_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/lounge_sidebar_white.svg"),
      name: i18n.t("community.myCommunities"),
      route: "profileCommunity",
    },
    {
      imageHover: require("@/assets/debate_sidebar_black.svg"),
      imageSelected: require("@/assets/debate_sidebar_purple.svg"),
      imageUnselected: require("@/assets/debate_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/debate_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/debate_sidebar_white.svg"),
      name: "Debates",
      route: "roundTableList",
    },
    {
      imageHover: require("@/assets/white_paper_sidebar_black.svg"),
      imageSelected: require("@/assets/white_paper_sidebar_purple.svg"),
      imageUnselected: require("@/assets/white_paper_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/white_paper_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/white_paper_sidebar_white.svg"),
      name: i18n.t("readingList.papers"),
      route: "readingListWhitePaperList",
    },
    {
      imageHover: require("@/assets/success_case_sidebar_black.svg"),
      imageSelected: require("@/assets/success_case_sidebar_purple.svg"),
      imageUnselected: require("@/assets/success_case_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/success_case_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/success_case_sidebar_white.svg"),
      name: i18n.t("readingList.successCase"),
      route: "readingListSuccessCase",
    },
    {
      imageHover: require("@/assets/favorites_sidebar_black.svg"),
      imageSelected: require("@/assets/favorites_sidebar_purple.svg"),
      imageUnselected: require("@/assets/favorites_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/favorites_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/favorites_sidebar_white.svg"),
      name: i18n.t("favorite.favorite"),
      route: "favoritesHome",
    },
    {
      imageHover: require("@/assets/reading_list_sidebar_black.svg"),
      imageSelected: require("@/assets/reading_list_sidebar_purple.svg"),
      imageUnselected: require("@/assets/reading_list_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/reading_list_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/reading_list_sidebar_white.svg"),
      name: i18n.t("readingList.readingList"),
      route: "readingListItems",
    },
    {
      imageHover: require("@/assets/message_sidebar_black.svg"),
      imageSelected: require("@/assets/message_sidebar_purple.svg"),
      imageUnselected: require("@/assets/message_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/message_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/message_sidebar_white.svg"),
      name: i18n.t("profile.messages"),
      route: "chatHome",
    },
    // {
    //   imageHover: require("@/assets/notification_sidebar_black.svg"),
    //   imageSelected: require("@/assets/notification_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/notification_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/notification_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/notification_sidebar_white.svg"),
    //   name: i18n.t("notification.title"),
    //   route: "newNotificationHome",
    // },
    // {
    //   imageHover: require("@/assets/profile_sidebar_black.svg"),
    //   imageSelected: require("@/assets/profile_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/profile_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/profile_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/profile_sidebar_white.svg"),
    //   name: i18n.t("profile.myProfile"),
    //   route: "newProfileHome",
    // },
  ];
  generalItemsExecutive = [
    {
      imageHover: require("@/assets/timeline_sidebar_black.svg"),
      imageSelected: require("@/assets/timeline_sidebar_purple.svg"),
      imageUnselected: require("@/assets/timeline_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/timeline_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/timeline_sidebar_white.svg"),
      name: "Timeline",
      route: "timelineHome",
    },
    {
      imageHover: require("@/assets/lounge_sidebar_black.svg"),
      imageSelected: require("@/assets/lounge_sidebar_purple.svg"),
      imageUnselected: require("@/assets/lounge_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/lounge_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/lounge_sidebar_white.svg"),
      name: i18n.t("community.myCommunities"),
      route: "profileCommunity",
    },
    // {
    //   imageHover: require("@/assets/debate_sidebar_black.svg"),
    //   imageSelected: require("@/assets/debate_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/debate_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/debate_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/debate_sidebar_white.svg"),
    //   name: "Debates",
    //   route: "timelineHome1",
    // },
    {
      imageHover: require("@/assets/white_paper_sidebar_black.svg"),
      imageSelected: require("@/assets/white_paper_sidebar_purple.svg"),
      imageUnselected: require("@/assets/white_paper_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/white_paper_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/white_paper_sidebar_white.svg"),
      name: i18n.t("readingList.papers"),
      route: "readingListWhitePaperList",
    },
    {
      imageHover: require("@/assets/success_case_sidebar_black.svg"),
      imageSelected: require("@/assets/success_case_sidebar_purple.svg"),
      imageUnselected: require("@/assets/success_case_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/success_case_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/success_case_sidebar_white.svg"),
      name: i18n.t("readingList.successCase"),
      route: "readingListSuccessCase",
    },
    {
      imageHover: require("@/assets/favorites_sidebar_black.svg"),
      imageSelected: require("@/assets/favorites_sidebar_purple.svg"),
      imageUnselected: require("@/assets/favorites_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/favorites_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/favorites_sidebar_white.svg"),
      name: "Leads",
      route: "leadsHome",
    },
    {
      imageHover: require("@/assets/reading_list_sidebar_black.svg"),
      imageSelected: require("@/assets/reading_list_sidebar_purple.svg"),
      imageUnselected: require("@/assets/reading_list_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/reading_list_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/reading_list_sidebar_white.svg"),
      name: i18n.t("readingList.readingListExecutive"),
      route: "readingListItems",
    },
    {
      imageHover: require("@/assets/message_sidebar_black.svg"),
      imageSelected: require("@/assets/message_sidebar_purple.svg"),
      imageUnselected: require("@/assets/message_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/message_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/message_sidebar_white.svg"),
      name: i18n.t("profile.messages"),
      route: "chatHome",
    },
    // {
    //   imageHover: require("@/assets/notification_sidebar_black.svg"),
    //   imageSelected: require("@/assets/notification_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/notification_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/notification_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/notification_sidebar_white.svg"),
    //   name: i18n.t("notification.title"),
    //   route: "newNotificationHome",
    // },
    // {
    //   imageHover: require("@/assets/profile_sidebar_black.svg"),
    //   imageSelected: require("@/assets/profile_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/profile_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/profile_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/profile_sidebar_white.svg"),
    //   name: i18n.t("profile.myProfile"),
    //   route: "newProfileHome",
    // },
  ];
  generalItemsPartner = [
    {
      imageHover: require("@/assets/timeline_sidebar_black.svg"),
      imageSelected: require("@/assets/timeline_sidebar_purple.svg"),
      imageUnselected: require("@/assets/timeline_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/timeline_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/timeline_sidebar_white.svg"),
      name: "Timeline",
      route: "timelineHome",
    },
    {
      imageHover: require("@/assets/lounge_sidebar_black.svg"),
      imageSelected: require("@/assets/lounge_sidebar_purple.svg"),
      imageUnselected: require("@/assets/lounge_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/lounge_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/lounge_sidebar_white.svg"),
      name: i18n.t("community.myCommunities"),
      route: "profileCommunity",
    },
    // {
    //   imageHover: require("@/assets/debate_sidebar_black.svg"),
    //   imageSelected: require("@/assets/debate_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/debate_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/debate_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/debate_sidebar_white.svg"),
    //   name: "Debates",
    //   route: "timelineHome1",
    // },
    {
      imageHover: require("@/assets/white_paper_sidebar_black.svg"),
      imageSelected: require("@/assets/white_paper_sidebar_purple.svg"),
      imageUnselected: require("@/assets/white_paper_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/white_paper_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/white_paper_sidebar_white.svg"),
      name: i18n.t("readingList.papers"),
      route: "readingListWhitePaperList",
    },
    {
      imageHover: require("@/assets/success_case_sidebar_black.svg"),
      imageSelected: require("@/assets/success_case_sidebar_purple.svg"),
      imageUnselected: require("@/assets/success_case_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/success_case_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/success_case_sidebar_white.svg"),
      name: i18n.t("readingList.successCase"),
      route: "readingListSuccessCase",
    },
    {
      imageHover: require("@/assets/favorites_sidebar_black.svg"),
      imageSelected: require("@/assets/favorites_sidebar_purple.svg"),
      imageUnselected: require("@/assets/favorites_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/favorites_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/favorites_sidebar_white.svg"),
      name: i18n.t("favorite.favorite"),
      route: "favoritesHome",
    },
    {
      imageHover: require("@/assets/reading_list_sidebar_black.svg"),
      imageSelected: require("@/assets/reading_list_sidebar_purple.svg"),
      imageUnselected: require("@/assets/reading_list_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/reading_list_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/reading_list_sidebar_white.svg"),
      name: i18n.t("readingList.readingList"),
      route: "readingListItems",
    },
    {
      imageHover: require("@/assets/message_sidebar_black.svg"),
      imageSelected: require("@/assets/message_sidebar_purple.svg"),
      imageUnselected: require("@/assets/message_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/message_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/message_sidebar_white.svg"),
      name: i18n.t("profile.messages"),
      route: "chatHome",
    },
    // {
    //   imageHover: require("@/assets/notification_sidebar_black.svg"),
    //   imageSelected: require("@/assets/notification_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/notification_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/notification_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/notification_sidebar_white.svg"),
    //   name: i18n.t("notification.title"),
    //   route: "newNotificationHome",
    // },
    // {
    //   imageHover: require("@/assets/profile_sidebar_black.svg"),
    //   imageSelected: require("@/assets/profile_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/profile_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/profile_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/profile_sidebar_white.svg"),
    //   name: i18n.t("profile.myProfile"),
    //   route: "newProfileHome",
    // },
  ];
  generalItemsEquipe = [
    {
      imageHover: require("@/assets/timeline_sidebar_black.svg"),
      imageSelected: require("@/assets/timeline_sidebar_purple.svg"),
      imageUnselected: require("@/assets/timeline_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/timeline_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/timeline_sidebar_white.svg"),
      name: "Timeline",
      route: "timelineHome",
    },
    {
      imageHover: require("@/assets/lounge_sidebar_black.svg"),
      imageSelected: require("@/assets/lounge_sidebar_purple.svg"),
      imageUnselected: require("@/assets/lounge_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/lounge_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/lounge_sidebar_white.svg"),
      name: i18n.t("community.myCommunities"),
      route: "profileCommunity",
    },
    // {
    //   imageHover: require("@/assets/debate_sidebar_black.svg"),
    //   imageSelected: require("@/assets/debate_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/debate_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/debate_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/debate_sidebar_white.svg"),
    //   name: "Debates",
    //   route: "roundTableList",
    // },
    {
      imageHover: require("@/assets/white_paper_sidebar_black.svg"),
      imageSelected: require("@/assets/white_paper_sidebar_purple.svg"),
      imageUnselected: require("@/assets/white_paper_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/white_paper_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/white_paper_sidebar_white.svg"),
      name: i18n.t("readingList.papers"),
      route: "readingListWhitePaperList",
    },
    {
      imageHover: require("@/assets/success_case_sidebar_black.svg"),
      imageSelected: require("@/assets/success_case_sidebar_purple.svg"),
      imageUnselected: require("@/assets/success_case_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/success_case_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/success_case_sidebar_white.svg"),
      name: i18n.t("readingList.successCase"),
      route: "readingListSuccessCase",
    },
    // {
    //   imageHover: require("@/assets/favorites_sidebar_black.svg"),
    //   imageSelected: require("@/assets/favorites_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/favorites_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/favorites_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/favorites_sidebar_white.svg"),
    //   name: i18n.t("favorite.favorite"),
    //   route: "favoritesHome",
    // },
    {
      imageHover: require("@/assets/reading_list_sidebar_black.svg"),
      imageSelected: require("@/assets/reading_list_sidebar_purple.svg"),
      imageUnselected: require("@/assets/reading_list_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/reading_list_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/reading_list_sidebar_white.svg"),
      name: i18n.t("readingList.readingList"),
      route: "readingListItems",
    },
    {
      imageHover: require("@/assets/message_sidebar_black.svg"),
      imageSelected: require("@/assets/message_sidebar_purple.svg"),
      imageUnselected: require("@/assets/message_sidebar_gray.svg"),
      imageHoverMini: require("@/assets/message_sidebar_pink.svg"),
      imageSelectedMini: require("@/assets/message_sidebar_white.svg"),
      name: i18n.t("profile.messages"),
      route: "chatHome",
    },
    // {
    //   imageHover: require("@/assets/notification_sidebar_black.svg"),
    //   imageSelected: require("@/assets/notification_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/notification_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/notification_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/notification_sidebar_white.svg"),
    //   name: i18n.t("notification.title"),
    //   route: "newNotificationHome",
    // },
    // {
    //   imageHover: require("@/assets/profile_sidebar_black.svg"),
    //   imageSelected: require("@/assets/profile_sidebar_purple.svg"),
    //   imageUnselected: require("@/assets/profile_sidebar_gray.svg"),
    //   imageHoverMini: require("@/assets/profile_sidebar_pink.svg"),
    //   imageSelectedMini: require("@/assets/profile_sidebar_white.svg"),
    //   name: i18n.t("profile.myProfile"),
    //   route: "newProfileHome",
    // },
  ];
  logoutItem = {
    imageHover: require("@/assets/logout_sidebar_black.svg"),
    imageSelected: require("@/assets/logout_sidebar_purple.svg"),
    imageUnselected: require("@/assets/logout_sidebar_gray.svg"),
    imageHoverMini: require("@/assets/logout_sidebar_pink.svg"),
    imageSelectedMini: require("@/assets/logout_sidebar_white.svg"),
    name: i18n.t("sidebar.logout"),
    route: "loginPage",
  };
  changeUserItem = {
    imageHover: require("@/assets/change_user_sidebar_black.svg"),
    imageSelected: require("@/assets/change_user_sidebar_purple.svg"),
    imageUnselected: require("@/assets/change_user_sidebar_gray.svg"),
    imageHoverMini: require("@/assets/change_user_sidebar_pink.svg"),
    imageSelectedMini: require("@/assets/change_user_sidebar_white.svg"),
    name: i18n.t("sidebar.changeUser"),
    route: "changeUser",
  };
  transparencyItem = {
    imageHover: require("@/assets/policy_sidebar_black.svg"),
    imageSelected: require("@/assets/policy_sidebar_purple.svg"),
    imageUnselected: require("@/assets/policy_sidebar_gray.svg"),
    imageHoverMini: require("@/assets/policy_sidebar_pink.svg"),
    imageSelectedMini: require("@/assets/policy_sidebar_white.svg"),
    name: i18n.t("transparency.sidebar"),
    route: "transparency",
  };
  drawer = true;
  filter = "";
  mini = false;
  TokenService = TokenService;
  urls: any = {
    development: "https://admin-dev.exclusivelounge.network",
    staging: "https://admin-staging.exclusivelounge.network",
    production: "https://admin.exclusivelounge.network",
  };
  @State("currentFilter") currentFilter: any;
  @State((state) => state.profile.me) me: any;
  @Action("updateFilter") updateFilter: any;
  @Action("profile/requestUpdateTheme") requestUpdateTheme: any;
  @Action("requestLogout") requestLogout: any;
  @Action("updateRouteAfterLogin") updateRouteAfterLogin: any;
  @Action("updateFilterInput") updateFilterInput: any;
  @Action("updateMiniSidebarValue") updateMiniSidebarValue: any;
  get isIOS(): boolean {
    return (
      Bowser.getParser(window.navigator.userAgent).getBrowser().name ===
      "Safari"
    );
  }
  get classButton(): string {
    return this.isIOS ? "button-ios" : "";
  }
  get currentUser(): any {
    return this.me;
  }
  get classMiniSidebar(): string {
    return this.mini ? "px-1" : "px-2";
  }
  get generalItems(): sidebar_items[] {
    return TokenService.getRole() === "EQUIPE"
      ? this.generalItemsEquipe
      : TokenService.getRole() === "PARTNER"
      ? this.generalItemsPartner
      : TokenService.getRole() === "EXECUTIVE"
      ? this.generalItemsExecutive
      : this.generalItemsClevel;
  }
  get colorBackground(): string {
    return this.$vuetify.breakpoint.mdAndDown
      ? "purple100"
      : !this.mini
      ? "white"
      : "purple100 disabled-button-gray";
  }
  created(): void {
    // this.drawer = this.$vuetify.breakpoint.mdAndUp;
    this.filter = this.currentFilter;
  }
  mounted(): void {
    let currentItem: any;
    this.generalItems.forEach((item: sidebar_items) => {
      let route = this.$route.name;
      if (route?.includes(item.route)) currentItem = item;
    });
    this.selected =
      this.transparencyItem.route === this.$route.name
        ? this.transparencyItem
        : currentItem;
  }
  updateMiniSidebar(): void {
    this.mini = !this.mini;
  }
  isSeletecItem(item: sidebar_items): boolean {
    return this.selected === item;
  }
  updateSelectedItem(item: any): void {
    if (item.route === "changeUser") {
      const hashids = new Hashids();
      const encode = hashids.encode("110997");
      const { VUE_APP_MODE } = process.env;
      const url = `${this.urls[VUE_APP_MODE]}/onboarding/${encode}/${this.$root.$i18n.locale}`;
      window.open(url, "_blank");
    } else {
      const newRoute = this.selected === item ? null : item;
      if (newRoute?.route === "loginPage") {
        this.updateRouteAfterLogin({ name: "", params: {} });
        this.requestLogout();
      }
      if (newRoute) this.$router.push({ name: newRoute.route });
    }
  }
  backgroundSelectedItem(item: sidebar_items): string {
    return this.$vuetify.breakpoint.mdAndDown
      ? this.isSeletecItem(item)
        ? `gray70--text ${this.colorBackground}`
        : `white--text ${this.colorBackground}`
      : this.isSeletecItem(item)
      ? `purple100--text ${this.colorBackground}`
      : `gray70--text ${this.colorBackground}`;
  }
  async updateTheme(item: boolean): Promise<void> {
    const payload = {
      theme: item ? "DARK" : "LIGHT",
    };
    await this.withLoading(async () => {
      await this.requestUpdateTheme(payload);
    });
  }
  redirectSearch(): void {
    if (this.$route.name !== "searchHome")
      this.$router.push({ name: "searchHome" });
  }
  redirectProfile(): void {
    if (this.$route.name !== "profileHome")
      this.$router.push({ name: "profileHome" });
  }
  redirectProfileFourNetwork(): void {
    if (this.$route.name !== "timelineFourNetworkSection")
      this.$router.push({ name: "timelineFourNetworkSection" });
  }
  updateStoreFilter(): void {
    if (!this.filter) return;
    this.updateFilter(!this.filter ? "" : this.filter);
    this.redirectSearch();
  }
  clearStoreFilter(): void {
    this.filter = "";
    this.updateFilter(!this.filter ? "" : this.filter);
    this.redirectSearch();
  }
  miniSidebar(): void {
    this.mini = !this.mini;
  }
  @Watch("mini") onMiniChanged(): void {
    this.updateMiniSidebarValue(this.mini);
  }
  @Watch("toggle", { immediate: true }) onItemChanged(): void {
    if (this.$vuetify.breakpoint.mdAndDown) this.drawer = this.toggle;
  }
  @Watch("filter", { immediate: true, deep: true })
  updateValueFilter(): void {
    this.updateFilterInput(!this.filter ? "" : this.filter);
  }
}
