
import { Component, Prop } from "vue-property-decorator";
import { ProfileUser, MiniProfile } from "../../modules/profile/types";
import { mixins } from "vue-class-component";
import { ModalMixin } from "../../mixins/ModalMixin";
@Component
export default class ModalImageProfile extends mixins(ModalMixin) {
  @Prop(Object) profile: ProfileUser | MiniProfile;
  @Prop(Object) img: any;
  style = "";
  show = false;
  styleImg = "";
  get imageItem(): any {
    return this.profile
      ? this.profile.profileImage
      : this.img.blob || this.img.mediaUrl;
  }
  get altPhoto(): string {
    return `${this.$t("misc.photo")}`;
  }
  mounted(): void {
    this.style = `width: auto; height: auto; overflow: hidden;`;
    this.styleImg = `width: auto; height: 80vh; overflow: hidden; object-fit: contain;`;
    setTimeout(() => {
      this.show = true;
    }, 500);
  }
}
