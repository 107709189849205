import API from "../api";
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async requestLeads(context: any, payload: any): Promise<any> {
      const { data } = await API.getLeads(payload);
      return data;
    },
    async requestDownloadLeads(): Promise<any> {
      const { data } = await API.getDownloadLeads();
      return data;
    },
  },
  modules: {},
};
