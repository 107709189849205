
import TheAlert from "../components/TheAlert.vue";
import { Prop } from "vue-property-decorator";
import Component, { mixins } from "vue-class-component";
import { State, Action } from "vuex-class";
import { TokenService } from "@/api/storage";
import { ActionMixin } from "../mixins/ActionMixin";
import LoadingModal from "../components/LoadingModal.vue";
import { api } from "@/api";
import _ from "lodash";
@Component({
  components: {
    TheAlert,
    LoadingModal,
  },
})
export default class Main extends mixins(ActionMixin) {
  @Prop({ default: "" }) title: any;
  @Prop({ default: "white" }) color: string;
  @Prop({ default: false }) routeFooter: boolean;
  @Prop({ default: "pa-4 px-md-0" }) padding: string;
  @Prop({ default: false }) button: boolean;
  @Prop({ default: "notification" }) actionButton: string;
  @Prop({ default: false }) isProfile: boolean;
  mini = false;
  toggle: any = null;
  isNotification = false;
  isMessageNotification = false;
  isNewCommunity = false;
  debounce = _.debounce(() => {
    this.fetchNotification();
  }, 60000);
  debounceCommunity = _.debounce(() => {
    this.fetchNotificationCommunity();
  }, 3600000);
  debounceOnline = _.debounce(() => {
    this.fetchIsOnline();
  }, 120000);
  // @Action("notification/requestIsNotification") requestIsNotification: any;
  @Action("notification/requestIsMessageNotification")
  requestIsMessageNotification: any;
  @Action("timeline/initializeAxios") initializeAxios: any;
  @State("alertNotification") alertNotification: any;
  @Action("community/requestIsNewCommunity") requestIsNewCommunity: any;
  @Action("community/requestCommunityPending") requestCommunityPending: any;
  @Action("profile/requestIsOnline") requestIsOnline: any;
  get classContent(): string {
    return `${this.color} ${this.padding}`;
  }
  created(): void {
    this.$root.$i18n.locale = TokenService.getLanguage() || "pt";
    this.$root.$i18n.fallbackLocale = TokenService.getLanguage() || "pt";
    if (!api) {
      this.initializeAxios().then(() => {
        this.fetchData();
      });
    } else {
      this.fetchData();
    }
  }
  fetchData(): void {
    this.fetchNotification();
    this.fetchNotificationCommunity();
    this.fetchIsOnline();
  }
  fetchNotification(): void {
    // this.fetchIsNotification();
    this.fetchIsMessageNotification();
  }
  fetchNotificationCommunity(): void {
    this.fetchIsIsNewCommunity();
    this.fetchCommunityNotification();
  }
  // async fetchIsNotification(): Promise<void> {
  //   await this.withLoading(async () => {
  //     await this.requestIsNotification().then((data: boolean) => {
  //       this.isNotification = data;
  //       this.debounce();
  //     });
  //   });
  // }
  async fetchIsMessageNotification(): Promise<void> {
    await this.withLoading(async () => {
      await this.requestIsMessageNotification().then((data: boolean) => {
        this.isMessageNotification = data;
        this.debounce();
      });
    });
  }
  async fetchIsIsNewCommunity(): Promise<void> {
    await this.withLoading(async () => {
      await this.requestIsNewCommunity().then((data: boolean) => {
        this.isNewCommunity = data;
        if (data)
          setTimeout(() => {
            this.removeNotification();
          }, 5000);
      });
    });
  }
  async fetchCommunityNotification(): Promise<void> {
    await this.withLoading(async () => {
      await this.requestCommunityPending().then(() => {
        this.debounceCommunity();
      });
    });
  }
  removeNotification(): void {
    this.isNewCommunity = false;
  }
  async fetchIsOnline(): Promise<void> {
    await this.withLoading(async () => {
      await this.requestIsOnline().then(() => {
        this.debounceOnline();
      });
    });
  }
  toggleSidebar(): void {
    this.toggle = !this.toggle;
  }
  beforeDestroy(): void {
    this.debounce.cancel();
    this.debounceCommunity.cancel();
    this.debounceOnline.cancel();
  }
}
