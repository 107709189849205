import App from "@/views/App.vue";

export default [
  {
    path: "/lista-de-leitura",
    name: "readingList",
    component: App,
    redirect: { name: "readingListHome" },
    children: [
      {
        path: "/lista-de-leitura/:id?",
        name: "readingListHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/News.vue"),
        meta: {
          displayName: "Lista de Leitura - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/lista-leitura",
        name: "readingListItems",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ReadingList.vue"),
        meta: {
          displayName: "Lista de Leitura - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/case-de-sucesso",
        name: "readingListSuccessCase",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/SuccessCase.vue"),
        meta: {
          displayName: "Case de sucesso - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/white-paper",
        name: "readingListWhitePaperList",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/WhitePaperList.vue"),
        meta: {
          displayName: "White paper - Exclusive Lounge",
          requiresAuth: true,
        },
      },
    ],
  },
];
