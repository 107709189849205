import App from "@/views/App.vue";

export default [
  {
    path: "/debate",
    name: "roundTable",
    component: App,
    redirect: { name: "roundTableHome" },
    children: [
      {
        path: "/novo-debate",
        name: "newRoundTableHome",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/NewDebateCreation.vue"
          ),
        meta: {
          displayName: "Novo Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/novo-post-debate/:id",
        name: "newPostRoundTable",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewPostDebate.vue"),
        meta: {
          displayName: "Novo Post Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/editar-post-debate/:id",
        name: "editPostRoundTable",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/NewEditPostRoundTable.vue"
          ),
        meta: {
          displayName: "Editar Post Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/debate/:id",
        name: "roundTableItems",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewDebate.vue"),
        meta: {
          displayName: "Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/lista-debate",
        name: "roundTableList",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/DebateList.vue"),
        meta: {
          displayName: "Debates - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/novo-debate/:id",
        name: "newRoundTableItems",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewPostDebate.vue"),
        meta: {
          displayName: "Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/novo-debate-criacao",
        name: "newDebateCreation",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/NewDebateCreation.vue"
          ),
        meta: {
          displayName: "Novo Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/novo-post-debate/:id",
        name: "newPostDebate",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewPostDebate.vue"),
        meta: {
          displayName: "Novo Post Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/novo-editar-post-round-table/:id",
        name: "newEditPostRoundTable",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/NewEditPostRoundTable.vue"
          ),
        meta: {
          displayName: "Editar Post Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
    ],
  },
];
