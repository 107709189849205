import { Component, Vue } from "vue-property-decorator";
@Component
export class MedalMixin extends Vue {
  imgInteractionMedal: any = {
    BRONZE: {
      timeline: require("@/assets/timelineBronze.svg"),
      profile: require("@/assets/bronzeMedal.svg"),
      details: require("@/assets/bronzeProfile.svg"),
      medal: require("@/assets/newBronzeMedal.svg"),
    },
    SILVER: {
      timeline: require("@/assets/timelineSilver.svg"),
      profile: require("@/assets/silverMedal.svg"),
      details: require("@/assets/silverProfile.svg"),
      medal: require("@/assets/newSilverMedal.svg"),
    },
    GOLD: {
      timeline: require("@/assets/timelineGold.svg"),
      profile: require("@/assets/goldMedal.svg"),
      details: require("@/assets/goldProfile.svg"),
      medal: require("@/assets/newGoldMedal.svg"),
    },
    DIAMOND: {
      timeline: require("@/assets/timelineDiamond.svg"),
      profile: require("@/assets/diamondMedal.svg"),
      details: require("@/assets/diamondProfile.svg"),
      medal: require("@/assets/newDiamondMedal.svg"),
    },
    INFLUENCER: {
      timeline: require("@/assets/timelineInfluencer.svg"),
      profile: require("@/assets/influencerMedal.svg"),
      details: require("@/assets/influencerProfile.svg"),
      medal: require("@/assets/newInfluencerMedal.svg"),
    },
  };
  imgInteractionLevel(item: any, type: any): string {
    return this.imgInteractionMedal[item.profileMedalType][type];
  }
}
