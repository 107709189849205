
import { Component, Vue, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
@Component
export default class NewSidebarItem extends Vue {
  @Prop(Object) item: any;
  @Prop({ default: false }) mini: boolean;
  @Prop({ default: false }) selected: boolean;
  @Prop(Boolean) isNotification: boolean;
  @Prop(Boolean) isMessageNotification: boolean;
  @Prop({ default: true }) isImage: boolean;
  @Prop({ default: false }) isFixed: boolean;
  @State((state) => state.community.notificationNewCommunity)
  notificationNewCommunity: any;
  get colorText(): string {
    const colorFixed = this.isFixed
      ? this.$vuetify.breakpoint.mdAndDown
        ? "gray70--text"
        : "gray90--text"
      : "gray70--text";
    const padding = this.isImage ? "pl-3" : "pl-7";
    const selected = this.$vuetify.breakpoint.mdAndDown
      ? "white--text"
      : "purple100--text";
    return this.selected
      ? `${selected} font-weight-bold ${padding}`
      : `${colorFixed} font-weight-bold ${padding}`;
  }
  get classButton(): string {
    return this.mini ? "button-sidebar-item" : "";
  }
  get customIcon(): any {
    return this.isFixed
      ? this.item.imageHover
      : this.$vuetify.breakpoint.mdAndDown
      ? this.selected
        ? this.item.imageSelectedMini
        : this.item.imageUnselected
      : this.selected
      ? this.mini
        ? this.item.imageSelectedMini
        : this.item.imageSelected
      : this.item.imageUnselected;
  }
}
