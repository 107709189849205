import { api } from "@/api";
import { AxiosResponse } from "axios";
class API {
  static getLeads(payload: any): Promise<AxiosResponse> {
    return api.get("/profile/lead", { params: payload.params });
  }
  static getDownloadLeads(): Promise<AxiosResponse> {
    return api.get("/profile/lead/export");
  }
}

export default API;
