import { api } from "@/api";
import { AxiosResponse } from "axios";
import {
  PayloadCommunityMembersList,
  PayloadMyCommunities,
  PayloadStatusCommunity,
  PayloadCommunityMembersPossibleToInvite,
} from "../types";
class API {
  static getCurrentCommunity(payload: number): Promise<AxiosResponse> {
    return api.get(`/community/${payload}/detail`);
  }
  static getFeedCommunity(payload: any): Promise<AxiosResponse> {
    return api.get(`/community/${payload.communityId}/posts`, {
      params: payload.params,
      headers: payload.headers,
    });
  }
  static getCommunities(): Promise<AxiosResponse> {
    return api.get("/community/list");
  }
  static getCommunityMembers(payload: any): Promise<AxiosResponse> {
    return api.get(`community/${payload.id}/users`, { params: payload.params });
  }
  static putStatusCommunity(
    payload: PayloadStatusCommunity
  ): Promise<AxiosResponse> {
    return api.put("/community/profile/status", payload);
  }
  static getIsNewCommunity(): Promise<AxiosResponse> {
    return api.get("/community/news");
  }
  static getCommunitiesActives(payload: string): Promise<AxiosResponse> {
    return api.get(`/community/list/actives${payload}`);
  }
  static getCommunityPending(): Promise<AxiosResponse> {
    return api.get("/community/pending");
  }
  static postNewCommunity(payload: any): Promise<AxiosResponse> {
    return api.post("/community", payload);
  }
  static putEditCommunity(payload: any): Promise<AxiosResponse> {
    return api.put("/community", payload);
  }
  static putInactivateCommunity(payload: any): Promise<AxiosResponse> {
    return api.put(`/community/${payload.communityId}/inactivate`, {
      params: payload,
    });
  }
  static getUsersToInvite(payload: any): Promise<AxiosResponse> {
    return api.get(`/community/${payload.communityId}/search/users/toInvite`, {
      params: payload.params,
    });
  }
  static putInviTeUser(payload: any): Promise<AxiosResponse> {
    return api.put(`/community/${payload.communityId}/invite`, payload);
  }
  static putInactivateUser(payload: any): Promise<AxiosResponse> {
    return api.put(
      `/community/${payload.communityId}/inactivate/${payload.profileId}`,
      payload
    );
  }
  static getMyCommunities(
    payload: PayloadMyCommunities
  ): Promise<AxiosResponse> {
    return api.get("/community/search", { params: payload });
  }
  static getCommunityMembersList(
    payload: PayloadCommunityMembersList
  ): Promise<AxiosResponse> {
    return api.get(`community/${payload.id}/members`, {
      params: payload.params,
    });
  }
  static getPossilbeUsersToInvite(
    payload: PayloadCommunityMembersPossibleToInvite
  ): Promise<AxiosResponse> {
    return api.get(`community/${payload.id}/possible-new-members`, {
      params: payload.params,
    });
  }
}

export default API;
