import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/src/locale/pt";
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        black: "#272727",
        gray90: "#4A4A4A",
        gray70: "#979797",
        gray50: "#BBBBBB",
        gray40: "#D5D5D5",
        gray30: "#E5E6E7",
        gray15: "#F6F7FA",
        purple100: "#661F7C",
        purple70: "#AD92B8",
        purple40: "#E7DBEA",
        green100: "#8DC63F",
        greenCLevel: "#50A755",
        greenAlert: "#1CE45A",
        orangeAlert: "#FF9500",
        redExecutive: "#C40000",
        redAlert: "#F1002E",
        waterProfile: "#78CCB3",
        redProfile: "#EE6F6F",
        orangeProfile: "#EEAC6F",
        yellowProfile: "#F2D589",
        greenProfile: "#A3D35D",
        blueProfile: "#86D5DA",
        purpleProfile: "#A686DA",
        pinkProfile: "#E47496",
        primary: "#661F7C",
        white: "#FFFFFF",
        blackLogin: "#2A2A2A",
        blackButton: "#1F1F1F",
        blue100: "#00447C",
        greenCommunity: "#5DB49C",
        grayLight: "#595959",
        bluePartner: "#2F396B",
        grayDark: "#828282",
        gray10: "#F1F2F4",
        tagDarkBlue: "#112D78",
        tagOrange: "#BE4718",
        tagBlue: "#3F4598",
        tagGreen: "#1B6336",
        grayDisabled: "#F7F7F7",
        tagRed: "#B11A37",
        tagPurple: "#882CA5",
        orangeNotification: "#FFA451",
        purpleSidebar: "#E282FA",
      },
      dark: {
        black: "#FFFFFF",
        gray90: "#7E7E7E",
        gray70: "#979797",
        gray50: "#BBBBBB",
        gray40: "#D5D5D5",
        gray30: "#E2E2E2",
        gray15: "#F3F3F3",
        purple100: "#5C2571",
        purple70: "#AD92B8",
        purple40: "#D8CBDD",
        green100: "#8DC63F",
        greenCLevel: "#50A755",
        greenAlert: "#1CE45A",
        orangeAlert: "#FF9500",
        redExecutive: "#C40000",
        redAlert: "#F1002E",
        waterProfile: "#78CCB3",
        redProfile: "#EE6F6F",
        orangeProfile: "#EEAC6F",
        yellowProfile: "#F2D589",
        greenProfile: "#A3D35D",
        blueProfile: "#86D5DA",
        purpleProfile: "#A686DA",
        pinkProfile: "#E47496",
        primary: "#661F7C",
        white: "#272727",
        blackLogin: "#2A2A2A",
        blackButton: "#1F1F1F",
        blue100: "#00447C",
        greenCommunity: "#5DB49C",
        grayLight: "#595959",
        bluePartner: "#2F396B",
        grayDark: "#828282",
        gray10: "#F1F2F4",
        tagDarkBlue: "#112D78",
        tagOrange: "#BE4718",
        tagBlue: "#3F4598",
        tagGreen: "#1B6336",
        grayDisabled: "#F7F7F7",
        tagRed: "#B11A37",
        tagPurple: "#882CA5",
        orangeNotification: "#FFA451",
        purpleSidebar: "#E282FA",
      },
    },
  },
});
