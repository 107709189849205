var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('fragment',[_c('quill-editor',_vm._g(_vm._b({ref:"quillComponent",class:_vm.isLeads
        ? 'quill-editor-leads'
        : _vm.isDebate
        ? 'quill-editor-debate'
        : _vm.me.medalBenefities.includes('ADVANCED_FORMATTING')
        ? 'quill-editor-ranking'
        : 'quill-editor-common',attrs:{"options":_vm.isLeads
        ? _vm.editorOptionLeads
        : _vm.me.medalBenefities.includes('ADVANCED_FORMATTING')
        ? _vm.editorOptionRanking
        : _vm.editorOption,"id":_vm.classQuill,"ondragstart":"event.preventDefault()","ondrop":"event.preventDefault()"},on:{"change":_vm.updateMentionList}},'quill-editor',[_vm.$attrs, _vm.$props],false),_vm.$listeners)),(_vm.classQuill)?_c('div',{staticClass:"redAlert--text body-2",domProps:{"innerHTML":_vm._s(_vm.$t('post.limitCaracter'))}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }