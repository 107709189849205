
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class TheToolbar extends Vue {
  @Prop(Boolean) ruleButton: boolean;
  redirectTimeline(): void {
    if (this.$route.name !== "imelineHome")
      this.$router.push({ name: "timelineHome" });
  }
}
