import App from "@/views/App.vue";

export default [
  {
    path: "/lounge",
    name: "community",
    component: App,
    redirect: { name: "communityHome" },
    children: [
      {
        path: "/lounge/:id",
        name: "communityHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewCommunity.vue"),
        meta: {
          displayName: "Lounge - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/lounge/membros/:id",
        name: "communityMembersHome",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/NewCommunityMembers.vue"
          ),
        meta: {
          displayName: "Membros do Lounge - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/meus-lounges",
        name: "profileCommunity",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/CommunityList.vue"),
        meta: {
          displayName: "Meus Lounges - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/novo-lounge",
        name: "newLounge",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewLounge.vue"),
        meta: {
          displayName: "Novo Lounge - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/editar-lounge/:id",
        name: "editLounge",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/EditLounge.vue"),
        meta: {
          displayName: "Editar Lounge - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/convidar-membros-lounge/:id",
        name: "inviteNewMembers",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/InviteNewMembers.vue"
          ),
        meta: {
          displayName: "Convidar Novos Membros - Exclusive Lounge",
          requiresAuth: true,
        },
      },
    ],
  },
];
