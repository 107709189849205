
import { CustomFileUpload } from "@/types";
import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";
import Bowser from "bowser";
@Component
export default class BaseUploadButton extends Vue {
  @Prop(String) idName: string;
  @Prop({ default: false }) dropFiles: boolean;
  @Prop(String) textButton: string;
  @Prop({ default: "" }) backgroundPhoto: string;
  @Prop({ default: 100 }) backgroundHeight: number;
  @Prop({ default: false }) big: boolean;
  @Prop({ default: false }) optional: boolean;
  @Prop({ default: true }) multiple: boolean;
  @Prop({ default: false }) files: boolean;
  headers = {
    "X-Csrf-Token": "xxxx",
  };
  dataToken = {
    _csrf_token: "xxxxxx",
  };
  addedFiles: any = [];
  isDragOver = false;

  get isFiles(): string {
    let result = Bowser.getParser(window.navigator.userAgent);
    return this.files
      ? ".pdf, image/*"
      : result.getBrowser().name === "Safari"
      ? "image/*, video/*"
      : "image/*, video/*, capture=camera";
  }
  get defaultConfigs() {
    const acceptItems = this.isFiles;
    return Object.assign(
      {
        ref: "upload",
        "add-index": false,
        accept: acceptItems,
        "put-action": "/upload/put",
        directory: false,
        headers: this.headers,
        data: this.dataToken,
        drop: this.dropFiles,
        "input-id": this.idName,
        "post-action": "/upload/post",
      },
      {
        ...this.$attrs,
        ...this.$props,
      }
    );
  }
  updateFiles(value: any): void {
    if (_.isNull(value)) return;
    if (_.isArray(value)) {
      value.forEach((item: CustomFileUpload) => {
        this.inputFilter(item);
      });
      this.$emit("input", value);
      this.addedFiles = [];
    } else {
      this.inputFilter(value);
      this.$emit("input", [value]);
      this.addedFiles = [];
    }
  }
  inputFilter(file: any) {
    file.blob = "";
    const URL = window.URL || window.webkitURL;
    if (URL && URL.createObjectURL) file.blob = URL.createObjectURL(file);
  }
  onDragEnter(event: DragEvent) {
    if (this.dropFiles) {
      event.preventDefault();
      this.isDragOver = true;
    }
  }

  onDragOver(event: DragEvent) {
    if (this.dropFiles) {
      event.preventDefault();
    }
  }

  onDragLeave(event: DragEvent) {
    // Verifica se o mouse saiu completamente do elemento
    const relatedTarget = event.relatedTarget as HTMLElement | null;
    if (!relatedTarget || !this.$el.contains(relatedTarget)) {
      this.isDragOver = false;
    }
  }

  onDrop(event: DragEvent) {
    this.isDragOver = false;
    if (this.dropFiles && event.dataTransfer) {
      const files = event.dataTransfer.files;

      if (files.length > 0) {
        const fileList = this.multiple ? Array.from(files) : [files[0]];

        this.addedFiles = fileList;
        this.updateFiles(fileList);
      }
    }
  }
}
