import API from "../api";
import { PayloadRoundTableList } from "../types";
import { Commit } from "vuex";
export default {
  namespaced: true,
  state: {
    updateDebate: false,
  },
  mutations: {
    setUpdateDebate(state: any, payload: boolean) {
      state.updateDebate = payload;
    },
  },
  actions: {
    async requestNewRoundTable(context: any, payload: any): Promise<any> {
      const { data } = await API.postNewRoundTable(payload);
      return data;
    },
    async requestNewPostRoundTable(context: any, payload: any): Promise<any> {
      const { data } = await API.postNewPostRoundTable(payload);
      return data;
    },
    async requestEditPostRoundTable(context: any, payload: any): Promise<any> {
      const { data } = await API.putEditPostRoundTable(payload);
      return data;
    },
    async requestRoundTable(context: any, payload: any): Promise<any> {
      const { data } = await API.getRoundTable(payload);
      return data;
    },
    async requestNewCommentRoundTable(
      context: any,
      payload: any
    ): Promise<any> {
      const { data } = await API.postCommentRoundTable(payload);
      return data;
    },
    async requestEditCommentRoundTable(
      context: any,
      payload: any
    ): Promise<any> {
      const { data } = await API.putCommentRoundTable(payload);
      return data;
    },
    async requestDeleteCommentRoundTable(
      context: any,
      payload: any
    ): Promise<any> {
      const { data } = await API.deleteCommentRoundTable(payload);
      return data;
    },
    async requestRoundTableList(
      context: any,
      payload: PayloadRoundTableList
    ): Promise<any> {
      const { data } = await API.getRoundTableList(payload);
      return data;
    },
    async requestRoundTableDetail(context: any, payload: string): Promise<any> {
      const { data } = await API.getRoundTableDetail(payload);
      return data;
    },
    async changeUpdateDebate(
      { commit }: { commit: Commit },
      payload: boolean
    ): Promise<any> {
      commit("setUpdateDebate", payload);
    },
  },
  modules: {},
};
