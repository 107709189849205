
import { Component, Prop } from "vue-property-decorator";
import OptionsPost from "./OptionsPost.vue";
import ModalImageProfile from "../../../components/modal/ModalImageProfile.vue";
import { ModalMixin } from "../../../mixins/ModalMixin";
import { mixins } from "vue-class-component";
import _ from "lodash";
import { formatDatePost } from "@/utils/format";
import { MedalMixin } from "@/mixins/MedalMixin";
@Component({
  components: {
    OptionsPost,
    ModalImageProfile,
  },
})
export default class UserPost extends mixins(ModalMixin, MedalMixin) {
  @Prop(Object) item: any;
  @Prop({ default: false }) isSmall: boolean;
  @Prop({ default: false }) readonly: boolean;
  @Prop({ default: false }) hideButton: boolean;
  @Prop({ default: false }) isModal: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) isPost: boolean;
  @Prop({ default: false }) isComment: boolean;
  @Prop({ default: false }) canFixed: boolean;
  @Prop({ default: false }) fixed: boolean;
  @Prop({ default: false }) isTable: boolean;
  @Prop({ default: false }) isRepost: boolean;
  @Prop({ default: false }) userRepost: boolean;
  @Prop({ default: false }) postRepost: boolean;
  @Prop({ default: false }) isRead: boolean;
  @Prop({ default: false }) isHeaderImage: boolean;
  @Prop(Object) commentItem: any;
  @Prop(String) commentType: string;
  @Prop({ default: false }) isWhitePaper: boolean;
  @Prop({ default: false }) showOriginal: boolean;
  @Prop({ default: false }) isCommunity: boolean;
  @Prop({ default: false }) isInvite: boolean;
  @Prop({ default: false }) canFollow: boolean;
  @Prop({ default: false }) follow: boolean;
  @Prop({ default: false }) isJournal: boolean;
  @Prop({ default: false }) isRoundTable: boolean;
  @Prop({ default: false }) fixPostLounge: boolean;
  @Prop({ default: false }) unfixPostLounge: boolean;
  @Prop({ default: false }) isDebate: boolean;
  @Prop(String) creationDate: string;
  @Prop({ default: false }) isSidebar: boolean;
  @Prop({ default: false }) miniSidebar: boolean;
  show = false;
  modalImage = false;
  formatDatePost = formatDatePost;
  get classDivButton(): string {
    return this.isHeaderImage
      ? "d-flex justify-center align-start user-post__col"
      : this.isJournal
      ? "ml-5 user-post__col"
      : "d-flex justify-end align-center user-post__col";
  }
  get classButton(): string {
    return this.isHeaderImage
      ? "px-0 footer-background user-post__button-with-background"
      : "px-0 post__button-options user-post__button justify-center";
  }
  get currentUser(): string {
    return this.item.role === "PARTICIPANT"
      ? "post__avatar-green"
      : this.item.role === "EXECUTIVE"
      ? "post__avatar-red"
      : this.item.role === "EQUIPE"
      ? "post__avatar-black"
      : this.item.role === "PARTNER"
      ? "post__avatar-blue"
      : "";
  }
  get sizeAvatar(): string {
    return this.isInvite || this.isTable || this.isSidebar
      ? "auto"
      : this.isSmall
      ? "2"
      : "1";
  }
  get sizeText(): string {
    return this.isInvite || this.isTable || this.isSidebar
      ? "10"
      : this.userRepost
      ? "8"
      : this.isSmall
      ? "9"
      : "10";
  }
  get classOptions(): string {
    return this.isModal
      ? "user-post__card-fixed"
      : this.isWhitePaper
      ? "user-post__card-white-paper"
      : this.isJournal
      ? "user-post__card-journal"
      : "user-post__card";
  }
  get spaceRepost(): string {
    return this.isTable
      ? "text-end text-sm-start"
      : this.postRepost
      ? "user-post__space-repost"
      : "";
  }
  get classNameText(): string {
    const color =
      (this.$route.name === "profileHome" ||
        this.$vuetify.breakpoint.smAndDown) &&
      this.isSidebar
        ? "white--text"
        : "gray90--text";
    const padding = ["MARKETING", "FOUR_NETWORK"].includes(this.item.role)
      ? "pt-2"
      : "";
    return `${color} ${padding}`;
  }
  openModal(action: string): void {
    if (_.isEmpty(this.commentItem)) {
      this.$emit("open", action);
    } else {
      const emit = {
        type: action,
        item: this.commentItem,
        typeComment: this.commentType,
      };
      this.$emit("openComment", emit);
    }
  }
  showOptions(): void {
    this.show = true;
    setTimeout(() => {
      this.show = false;
    }, 5000);
  }
  showModalImage(): void {
    this.blockScroll();
    this.modalImage = true;
  }
  closeModalImage(): void {
    this.unblockScroll();
    this.modalImage = false;
  }
}
