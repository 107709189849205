import { Commit } from "vuex";
import API from "../api";
import {
  PayloadDenounce,
  PayloadReaction,
  PayloadComment,
  PayloadReactionComment,
  PayloadAnswer,
  PayloadEditComment,
  PayloadEditAnswer,
  PayloadDenounceComment,
  PayloadTimeline,
  PayloadCA,
  PayloadOnboarding,
  PayloadAcceptTerms,
  PayloadIsRead,
} from "../types";
import { TokenService } from "@/api/storage";
import { initialize } from "@/api";
import { UserToken, PayloadAuth } from "../types";
import { Dispatch } from "vuex";
export default {
  namespaced: true,
  state: {
    currentProfile: {
      name: "fgsf",
      photo: require("@/assets/perfil.jpg"),
      company: "Vsdfs  sdfsdf",
      type: "marketing",
      id: 123456,
    },
    denounceItems: [],
    headersTimeline: "",
    headersForYou: "",
    headersNewTimeline: "",
  },
  mutations: {
    setDenounceItems(state: any, payload: any) {
      state.denounceItems = payload;
    },
    setHeadersTimeline(state: any, payload: any) {
      state.headersTimeline = payload;
    },
    setHeadersForYou(state: any, payload: any) {
      state.headersForYou = payload;
    },
    setHeadersNewTimeline(state: any, payload: any) {
      state.headersNewTimeline = payload;
    },
  },
  actions: {
    storeToken(context: any, payload: UserToken): void {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
      TokenService.saveToken(JSON.stringify(payload.token));
      if (payload.refresh) {
        TokenService.saveRefreshToken(JSON.stringify(payload.refresh));
      }
      initialize(payload.token);
    },
    async requestLoginAuth(
      { dispatch }: { dispatch: Dispatch },
      payload: PayloadAuth
    ): Promise<UserToken> {
      const { data } = await API.getToken(payload);
      dispatch("storeToken", data);
      return data;
    },
    async requestLogin(
      { dispatch }: { dispatch: Dispatch },
      payload: PayloadOnboarding
    ): Promise<UserToken> {
      const { data } = await API.getTokenUser(payload);
      dispatch("storeToken", data);
      return data;
    },
    async requestTimeline(context: any, payload: any): Promise<any> {
      const { data } = await API.getTimeline(payload);
      return data;
    },
    async requestReactions(context: any, payload: string): Promise<any> {
      const { data } = await API.getReactions(payload);
      return data;
    },
    async requestDenounceItems({ commit }: { commit: Commit }): Promise<any> {
      const { data } = await API.getDenounce();
      commit("setDenounceItems", data);
      return data;
    },
    async requestDenouncePost(
      context: any,
      payload: PayloadDenounce
    ): Promise<any> {
      const { data } = await API.postDenounce(payload);
      return data;
    },
    async requestReactionPost(
      context: any,
      payload: PayloadReaction
    ): Promise<any> {
      const { data } = await API.postReactionPost(payload);
      return data;
    },
    async requestCurrentPost(context: any, payload: string): Promise<any> {
      const { data } = await API.getCurrentPost(payload);
      return data;
    },
    async requestCommentPost(
      context: any,
      payload: PayloadComment
    ): Promise<any> {
      const { data } = await API.postComment(payload);
      return data;
    },
    async requestReactionComment(
      context: any,
      payload: PayloadReactionComment
    ): Promise<any> {
      const { data } = await API.postReactionComment(payload);
      return data;
    },
    async requestAnswerPost(
      context: any,
      payload: PayloadAnswer
    ): Promise<any> {
      const { data } = await API.postAnswer(payload);
      return data;
    },
    async requestReactionsComment(context: any, payload: string): Promise<any> {
      const { data } = await API.getReactionsComment(payload);
      return data;
    },
    async requestEditComment(
      context: any,
      payload: PayloadEditComment
    ): Promise<any> {
      const { data } = await API.putComment(payload);
      return data;
    },
    async requestEditAnswer(
      context: any,
      payload: PayloadEditAnswer
    ): Promise<any> {
      const { data } = await API.putAnswer(payload);
      return data;
    },
    async requestDeleteComment(context: any, payload: string): Promise<any> {
      const { data } = await API.deleteComment(payload);
      return data;
    },
    async requestDenounceComment(
      context: any,
      payload: PayloadDenounceComment
    ): Promise<any> {
      const { data } = await API.postDenouncComment(payload);
      return data;
    },
    async requestInactivatePost(context: any, payload: any): Promise<any> {
      const { data } = await API.postInactivate(payload);
      return data;
    },
    async requestTimelineUser(
      context: any,
      payload: PayloadTimeline
    ): Promise<any> {
      const { data } = await API.getTimelineUser(payload);
      return data;
    },
    async requestInactivateComment(context: any, payload: any): Promise<any> {
      const { data } = await API.postInactivateComment(payload);
      return data;
    },
    initializeAxios(): void {
      initialize(TokenService.getToken());
    },
    async requestRegisterUserCA(
      context: any,
      payload: PayloadCA
    ): Promise<any> {
      const { data } = await API.postRegisterUserCA(payload);
      return data;
    },
    async requestUpdateStatusVideo(
      context: any,
      payload: number
    ): Promise<any> {
      const { data } = await API.getUpdateStatusVideo(payload);
      return data;
    },
    async requestRegisterUserPost(context: any, payload: number): Promise<any> {
      const { data } = await API.getRegisterUserPost(payload);
      return data;
    },
    async requestDeletePost(context: any, payload: number): Promise<any> {
      const { data } = await API.putDeletePost(payload);
      return data;
    },
    async requestPostAnswer(
      context: any,
      payload: number | string
    ): Promise<any> {
      const { data } = await API.getPostAnswer(payload);
      return data;
    },
    async requestFixPost(context: any, payload: any): Promise<any> {
      const { data } = await API.getFixPost(payload);
      return data;
    },
    async requestSuggestionInterest(context: any, payload: any): Promise<any> {
      const { data } = await API.postSuggestionInterest(payload);
      return data;
    },
    async requestFeed(
      { commit, state }: { commit: Commit; state: any },
      payload: any
    ): Promise<any> {
      if (payload.page > 0) {
        payload = {
          ...payload,
          headers: {
            "hash-page-controll": state.headersTimeline,
          },
        };
      }
      const { data, headers } = await API.getFeed(payload);
      commit("setHeadersTimeline", headers["hash-page-controll"]);
      return data;
    },
    async requestForYou(
      { commit, state }: { commit: Commit; state: any },
      payload: any
    ): Promise<any> {
      if (payload.page > 0) {
        payload = {
          ...payload,
          headers: {
            "hash-page-controll": state.headersForYou,
          },
        };
      }
      const { data, headers } = await API.getForYou(payload);
      commit("setHeadersForYou", headers["hash-page-controll"]);
      return data;
    },
    async requestAcceptTerms(
      context: any,
      payload: PayloadAcceptTerms
    ): Promise<any> {
      const { data } = await API.getAcceptTerms(payload);
      return data;
    },
    async requestTimelineFourNetwork(context: any, payload: any): Promise<any> {
      const { data } = await API.getTimelineFourNetwork(payload);
      return data;
    },
    async requestTerms(): Promise<any> {
      const { data } = await API.getTerms();
      return data;
    },
    async requestAbout(): Promise<any> {
      const { data } = await API.getAbout();
      return data;
    },
    async requestisReadPost(
      context: any,
      payload: PayloadIsRead
    ): Promise<any> {
      const { data } = await API.postReadingList(payload);
      return data;
    },
    async requestGetIsRead(context: any, payload: any): Promise<any> {
      const { data } = await API.getReadingList(payload);
      return data;
    },
    async requestCaseUser(context: any, payload: any): Promise<any> {
      const { data } = await API.getCaseUser(payload);
      return data;
    },
    async requestFeature(context: any, payload: string): Promise<any> {
      const { data } = await API.putFeature(payload);
      return data;
    },
    async requestSidebarItems(): Promise<any> {
      const { data } = await API.getSidebarItems();
      return data;
    },
    async requestNewTimeline(
      { commit, state }: { commit: Commit; state: any },
      payload: any
    ): Promise<any> {
      if (payload.page > 0) {
        payload = {
          ...payload,
          headers: {
            "hash-page-controll": state.headersNewTimeline,
          },
        };
      }
      const { data, headers } = await API.getNewTimeline(payload);
      commit("setHeadersNewTimeline", headers["hash-page-controll"]);
      return data;
    },
    async requestCompanyClick(context: any, payload: any) {
      const { data } = await API.putCompanyClick(payload);
      return data;
    },
    async requestTranslate(context: any, payload: any): Promise<any> {
      const { data } = await API.postTranslate(payload);
      return data;
    },
    async requestUpdateFixedList(context: any, payload: any) {
      const { data } = await API.postFixedList(payload);
      return data;
    },
  },
  modules: {},
};
