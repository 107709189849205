
import { MiniProfile } from "@/modules/profile/types";
import { Component, Vue, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import i18n from "@/plugins/i18n";
@Component
export default class OptionsPost extends Vue {
  @Prop(Object) profile: MiniProfile;
  @Prop(Boolean) isPost: boolean;
  @Prop(Boolean) isComment: boolean;
  @Prop(Boolean) canFixed: boolean;
  @Prop(Boolean) fixed: boolean;
  @Prop(Boolean) isRepost: boolean;
  @Prop(Boolean) isRead: boolean;
  @Prop(Boolean) isWhitePaper: boolean;
  @Prop(Boolean) showOriginal: boolean;
  @Prop(Boolean) isCommunity: boolean;
  @Prop(Boolean) isRoundTable: boolean;
  @Prop(Boolean) fixPostLounge: boolean;
  @Prop(Boolean) unfixPostLounge: boolean;
  hovered = "";
  optionsItems = [
    {
      name: i18n.t("timeline.openCommunity"),
      action: "openCommunity",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("mocks.openPost"),
      action: "modal",
      img: require("@/assets/eyes.svg"),
      imgHover: require("@/assets/eyesPurple.svg"),
    },
    {
      name: i18n.t("post.openOriginalPost"),
      action: "openOriginal",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("mocks.openProfile"),
      action: "profile",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("misc.editPost"),
      action: "edit",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("mocks.editComment"),
      action: "editComment",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("mocks.deletePost"),
      action: "deletePost",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("mocks.deleteComment"),
      action: "deleteComment",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("post.fixPost"),
      action: "fixPost",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("post.unfixPost"),
      action: "unfixPost",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("mocks.denounceComment"),
      action: "denounceComment",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("mocks.denouncePost"),
      action: "denouncePost",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("readingList.readMode"),
      action: "readMode",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("readingList.removeReadMode"),
      action: "removeReadMode",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("timeline.copyLink"),
      action: "copyLink",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("roundTable.copyLink"),
      action: "copyLinkRoundTable",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("post.fixPostLounge"),
      action: "fixPostLounge",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
    {
      name: i18n.t("post.unfixPostLounge"),
      action: "unfixPostLounge",
      img: require("@/assets/editGray.svg"),
      imgHover: require("@/assets/editPurple.svg"),
    },
  ];
  @State((state) => state.profile.me) me: any;
  get optionsAccordingUser(): any {
    return this.optionsItems.filter((x: any) => this.showOptions(x));
  }
  showOptions(item: any): any {
    return (
      (item.action !== "edit" ||
        (item.action === "edit" &&
          this.profile.id === this.me.id &&
          !this.isComment)) &&
      (item.action !== "modal" || (item.action === "modal" && this.isPost)) &&
      (item.action !== "profile" ||
        (item.action === "profile" &&
          ((this.profile.role === "FOUR_NETWORK" &&
            this.$route.name !== "timelineHome") ||
            this.profile.role !== "FOUR_NETWORK") &&
          this.profile.id !== this.me.id &&
          !["profileHome", "profileCLevel", "profileMarketing"].includes(
            String(this.$route.name)
          ))) &&
      (item.action !== "editComment" ||
        (item.action === "editComment" &&
          this.profile.id === this.me.id &&
          this.isComment)) &&
      (item.action !== "deleteComment" ||
        (item.action === "deleteComment" &&
          this.profile.id === this.me.id &&
          this.isComment)) &&
      (item.action !== "denounceComment" ||
        (item.action === "denounceComment" &&
          this.me.role !== "FOUR_NETWORK" &&
          this.profile.id !== this.me.id &&
          this.profile.role !== "FOUR_NETWORK" &&
          this.isComment)) &&
      (item.action !== "denouncePost" ||
        (item.action === "denouncePost" &&
          this.me.role !== "FOUR_NETWORK" &&
          this.profile.id !== this.me.id &&
          this.profile.role !== "FOUR_NETWORK" &&
          !this.isWhitePaper &&
          !this.isComment)) &&
      (item.action !== "deletePost" ||
        (item.action === "deletePost" &&
          this.profile.id === this.me.id &&
          !this.isComment)) &&
      (item.action !== "fixPost" ||
        (item.action === "fixPost" &&
          this.profile.id === this.me.id &&
          this.canFixed &&
          !this.fixed &&
          this.isPost)) &&
      (item.action !== "unfixPost" ||
        (item.action === "unfixPost" &&
          this.profile.id === this.me.id &&
          !this.canFixed &&
          this.fixed &&
          this.isPost)) &&
      (item.action !== "openOriginal" ||
        (item.action === "openOriginal" &&
          this.isPost &&
          this.isRepost &&
          this.showOriginal)) &&
      (item.action !== "readMode" ||
        (item.action === "readMode" &&
          this.profile.id !== this.me.id &&
          !this.isRead &&
          this.isPost &&
          !this.isWhitePaper)) &&
      (item.action !== "removeReadMode" ||
        (item.action === "removeReadMode" &&
          this.profile.id !== this.me.id &&
          this.isRead &&
          this.isPost &&
          !this.isWhitePaper)) &&
      (item.action !== "openCommunity" ||
        (item.action === "openCommunity" &&
          this.isPost &&
          this.isCommunity &&
          this.$route.name !== "communityHome")) &&
      (item.action !== "copyLink" ||
        (item.action === "copyLink" && this.isPost)) &&
      (item.action !== "copyLinkRoundTable" ||
        (item.action === "copyLinkRoundTable" &&
          this.isPost &&
          this.isRoundTable)) &&
      (item.action !== "fixPostLounge" ||
        (item.action === "fixPostLounge" && this.fixPostLounge)) &&
      (item.action !== "unfixPostLounge" ||
        (item.action === "unfixPostLounge" && this.unfixPostLounge))
    );
  }
}
