var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"pb-3 user-post__min-size",class:_vm.isTable
      ? 'd-flex justify-sm-start justify-end text-end text-sm-start'
      : _vm.isHeaderImage
      ? 'user-post__min-height'
      : ''},[(!_vm.isJournal)?_c('v-col',{staticClass:"d-flex justify-start align-center user-post__div-avatar",class:_vm.isInvite || _vm.isTable || _vm.isSidebar ? 'pr-4 pr-sm-1 pr-md-2' : '',attrs:{"cols":_vm.isInvite || _vm.isTable ? 'auto' : _vm.isSidebar ? '3' : '2',"sm":_vm.sizeAvatar,"md":_vm.isTable ? 'auto' : _vm.isSidebar ? '2' : _vm.sizeAvatar}},[(!_vm.isHeaderImage)?_c('v-avatar',{class:_vm.currentUser,attrs:{"size":"40"},on:{"click":_vm.showModalImage}},[_c('img',{attrs:{"src":_vm.item.profileImage}})]):_vm._e(),(!_vm.isHeaderImage && _vm.item.profileMedalType)?_c('v-avatar',{staticClass:"user-post__interactionMedal",attrs:{"size":"20"}},[_c('img',{attrs:{"src":_vm.imgInteractionLevel(_vm.item, 'timeline')}})]):_vm._e()],1):_vm._e(),(!_vm.isJournal && !_vm.miniSidebar)?_c('v-col',{staticClass:"user-post__text",class:_vm.spaceRepost,attrs:{"cols":_vm.isInvite ? '10' : _vm.isTable ? 'auto' : _vm.isSidebar ? '9' : '8',"sm":_vm.isDebate ? 7 : _vm.sizeText,"md":_vm.isDebate
        ? 8
        : _vm.isTable || _vm.isInvite
        ? 'auto'
        : _vm.isSidebar
        ? '10'
        : _vm.sizeText}},[(!_vm.isHeaderImage)?_c('div',{staticClass:"d-flex justify-start"},[_c('p',{staticClass:"body-2 font-weight-bold mb-0 d-inline-flex",class:_vm.classNameText},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),(_vm.item.hasMedal)?_c('img',{staticClass:"pl-1",attrs:{"src":require("@/assets/medal.svg")}}):_vm._e()]):_vm._e(),(
        !['MARKETING', 'FOUR_NETWORK'].includes(_vm.item.role) && !_vm.isHeaderImage
      )?_c('p',{staticClass:"caption mb-0",class:(_vm.$route.name === 'profileHome' || _vm.$vuetify.breakpoint.smAndDown) &&
        _vm.isSidebar
          ? 'white--text'
          : 'gray70--text'},[_vm._v(" "+_vm._s(_vm.item.companyName)+" ")]):_vm._e()]):_vm._e(),(!_vm.isDebate && !_vm.isSidebar && !_vm.isInvite && !_vm.hideButton && !_vm.readonly)?_c('v-col',{class:_vm.classDivButton,attrs:{"cols":_vm.isDebate ? 3 : 2,"sm":_vm.isDebate ? 3 : 1}},[_c('p',{staticClass:"mb-0 gray90--text body-2 pr-6"},[_vm._v(" "+_vm._s(_vm.creationDate)+" ")]),(_vm.canFollow)?_c('BaseButton',{staticClass:"purple100--text mr-3",attrs:{"text":""},domProps:{"innerHTML":_vm._s(_vm.follow ? _vm.$t('profile.influencer') : '+ ' + _vm.$t('profile.follow'))},on:{"click":function($event){return _vm.$emit('follow')}}}):_vm._e(),_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('BaseButton',_vm._g({class:_vm.classButton,attrs:{"text":""}},on),[(_vm.isJournal)?_c('img',{attrs:{"src":require("@/assets/menu_dotsWhite.svg")}}):_c('img',{attrs:{"src":require("@/assets/menu_dots.svg")}})])]}}],null,false,2346057942)},[_c('OptionsPost',{attrs:{"profile":_vm.item,"is-post":_vm.isPost,"is-comment":_vm.isComment,"can-fixed":_vm.canFixed,"fixed":_vm.fixed,"is-repost":_vm.isRepost,"is-read":_vm.isRead,"is-white-paper":_vm.isWhitePaper,"show-original":_vm.showOriginal,"is-community":_vm.isCommunity,"fix-post-lounge":_vm.fixPostLounge,"unfix-post-lounge":_vm.unfixPostLounge},on:{"open":_vm.openModal}})],1),(_vm.modalImage)?_c('ModalImageProfile',{attrs:{"profile":_vm.item},on:{"close":_vm.closeModalImage},model:{value:(_vm.modalImage),callback:function ($$v) {_vm.modalImage=$$v},expression:"modalImage"}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }