var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-btn',_vm._g(_vm._b({class:'d-flex fill-height elevation-0 py-2 px-3 justify-start new-sideber-item ' +
    _vm.classButton,attrs:{"min-height":"20px","width":"100%","disabled":_vm.isFixed}},'v-btn',[_vm.$attrs, _vm.$props],false),_vm.$listeners),[(_vm.isImage)?_c('img',{staticClass:"button-common",attrs:{"src":_vm.customIcon}}):_vm._e(),(_vm.isImage)?_c('img',{staticClass:"button-hover",attrs:{"src":_vm.mini
        ? _vm.item.imageHoverMini
        : _vm.$vuetify.breakpoint.mdAndDown
        ? _vm.customIcon
        : _vm.item.imageHover}}):_vm._e(),(!_vm.isImage)?_c('v-badge',{staticClass:"button-common pr-2",attrs:{"color":_vm.selected ? 'orangeAlert' : 'gray50',"dot":""}}):_vm._e(),(!_vm.isImage && !_vm.mini)?_c('v-badge',{staticClass:"button-hover pr-2",attrs:{"color":"black","dot":""}}):_vm._e(),(
      (_vm.item.route === 'notificationHome' && _vm.isNotification) ||
      (_vm.item.route === 'chatHome' && _vm.isMessageNotification) ||
      (_vm.item.route === 'profileHome' && _vm.notificationNewCommunity)
    )?_c('v-badge',{staticClass:"bagde-sidebar",attrs:{"color":"orangeAlert","dot":""}}):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mini),expression:"!mini"}],class:'mb-0 ml-0 item__text pt-0 ' +
      _vm.colorText +
      (_vm.isFixed ? ' body-2' : ' subtitle-1')},[_vm._v(" "+_vm._s(_vm.item.name)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }