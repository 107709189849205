import { render, staticRenderFns } from "./BaseFormTextArea.vue?vue&type=template&id=5e4beb5c"
import script from "./BaseFormTextArea.vue?vue&type=script&lang=ts"
export * from "./BaseFormTextArea.vue?vue&type=script&lang=ts"
import style0 from "./BaseFormTextArea.vue?vue&type=style&index=0&id=5e4beb5c&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports