
import i18n from "@/plugins/i18n";
import Component, { mixins } from "vue-class-component";
import { ActionMixin } from "../mixins/ActionMixin";
import { Action, State } from "vuex-class";
import { FilterSidebarItem, SidebarRightItem } from "../types";
import { Prop, Watch } from "vue-property-decorator";
import { formatDayMonthYear } from "@/utils/format";
import TagItem from "@/components/TagItem.vue";
import { Event } from "@/types";
@Component({
  components: {
    TagItem,
  },
})
export default class SidebarFilter extends mixins(ActionMixin) {
  @Prop(Object) debate: any;
  @Prop(Object) community: any;
  @Prop(Number) currentLoadingLoungeId: number;
  @Prop(Boolean) disabled: boolean;
  @Prop({ default: false }) toggle: boolean;
  filterLoungeHeader = {
    imageHover: require("@/assets/lounge_sidebar_black.svg"),
    imageSelected: require("@/assets/lounge_sidebar_purple.svg"),
    imageUnselected: require("@/assets/lounge_sidebar_gray.svg"),
    name: i18n.t("sidebar.filterLounge"),
    route: "",
  };
  filterEventHeader = {
    imageHover: require("@/assets/event_sidebar_black.svg"),
    imageSelected: require("@/assets/event_sidebar_purple.svg"),
    imageUnselected: require("@/assets/event_sidebar_gray.svg"),
    name: i18n.t("sidebar.filterEvent"),
    route: "",
  };
  debateHeader = {
    imageHover: require("@/assets/debate_sidebar_black.svg"),
    imageSelected: require("@/assets/debate_sidebar_purple.svg"),
    imageUnselected: require("@/assets/debate_sidebar_gray.svg"),
    name: i18n.t("sidebar.debate"),
    route: "",
  };
  highlightsHeader = {
    imageHover: require("@/assets/metrics_sidebar_black.svg"),
    imageSelected: require("@/assets/metrics_sidebar_purple.svg"),
    imageUnselected: require("@/assets/metrics_sidebar_gray.svg"),
    name: i18n.t("sidebar.highlights"),
    route: "",
  };
  mostActiveUsers = {
    imageHover: require("@/assets/groupBlack.svg"),
    imageSelected: require("@/assets/groupBlack.svg"),
    imageUnselected: require("@/assets/groupBlack.svg"),
    name: i18n.t("sidebar.mostActiveUsers"),
    route: "",
  };
  relatedDebates = {
    imageHover: require("@/assets/debate_sidebar_black.svg"),
    imageSelected: require("@/assets/debate_sidebar_purple.svg"),
    imageUnselected: require("@/assets/debate_sidebar_gray.svg"),
    name: i18n.t("sidebar.relatedDebates"),
    route: "",
  };
  aboutLounge = {
    imageHover: require("@/assets/communityBlack.svg"),
    imageSelected: require("@/assets/communityBlack.svg"),
    imageUnselected: require("@/assets/communityBlack.svg"),
    name: i18n.t("community.about"),
    route: "",
  };
  loungeMembers = {
    imageHover: require("@/assets/members.svg"),
    imageSelected: require("@/assets/members.svg"),
    imageUnselected: require("@/assets/members.svg"),
    name: i18n.t("community.members"),
    route: "",
  };
  suggestedCompanies = {
    imageHover: require("@/assets/empresasBlack.svg"),
    imageSelected: require("@/assets/empresasBlack.svg"),
    imageUnselected: require("@/assets/empresasBlack.svg"),
    name: i18n.t("sidebar.suggestedCompanies"),
    route: "",
  };
  drawer = true;
  items: SidebarRightItem | null = null;
  selectedLounges: FilterSidebarItem[] = [];
  selectedEvents: FilterSidebarItem[] = [];
  members = [
    { img: require("@/assets/community.svg"), name: "José Almeida" },
    { img: require("@/assets/community.svg"), name: "Shofia Lemos" },
    { img: require("@/assets/community.svg"), name: "José Almeida" },
    { img: require("@/assets/community.svg"), name: "Shofia Lemos" },
    { img: require("@/assets/community.svg"), name: "José Almeida" },
  ];
  formatDayMonthYear = formatDayMonthYear;
  loungesFilter: any = [];
  allLounges = false;
  eventsFilter: any = [];
  allEvents = false;
  @State((state) => state.profile.me) me: any;
  @Action("timeline/requestSidebarItems") requestSidebarItems: any;
  @Action("updateSelectedLounge") updateSelectedLounge: any;
  @Action("updateSelectedEvent") updateSelectedEvent: any;
  @Action("roundTable/changeUpdateDebate") changeUpdateDebate: any;
  get currentUser(): string {
    return this.debate?.user?.role === "PARTICIPANT"
      ? "avatar-green"
      : this.debate?.user?.role === "EXECUTIVE"
      ? "avatar-red"
      : this.debate?.user?.role === "EQUIPE"
      ? "avatar-black"
      : this.debate?.user?.role === "PARTNER"
      ? "avatar-blue"
      : "";
  }
  get debateLocation(): string {
    return this.debate?.events?.length === 0 &&
      this.debate?.communities?.length === 0
      ? require("@/assets/localDebate.svg")
      : this.debate?.communities?.length > 0
      ? require("@/assets/lounge_sidebar_purple.svg")
      : require("@/assets/event_sidebar_purple.svg");
  }
  created(): void {
    this.fetchData();
  }
  fetchData(): void {
    this.withLoading(async () => {
      await this.requestSidebarItems().then((data: SidebarRightItem) => {
        this.items = data;
        this.loungesFilter.push(data.lounges.slice(0, 5));
        this.eventsFilter.push(data.events.slice(0, 4));
      });
    });
  }
  selectItem(item: FilterSidebarItem, type: string): void {
    if (type === "event") {
      if (
        !this.selectedEvents.find(
          (element: FilterSidebarItem) => element.id === item.id
        )
      ) {
        this.selectedEvents.push(item);
      } else {
        this.selectedEvents = this.selectedEvents.filter(
          (element: FilterSidebarItem) => element.id !== item.id
        );
      }
      this.updateSelectedEvent(this.selectedEvents);
    } else {
      if (
        !this.selectedLounges.find(
          (element: FilterSidebarItem) => element.id === item.id
        )
      ) {
        this.selectedLounges.push(item);
      } else {
        this.selectedLounges = this.selectedLounges.filter(
          (element: FilterSidebarItem) => element.id !== item.id
        );
      }
      this.updateSelectedLounge(this.selectedLounges);
    }
  }
  classButton(item: FilterSidebarItem, type: string): string {
    if (type === "event") {
      return this.selectedEvents.find(
        (element: FilterSidebarItem) => element.id === item.id
      )
        ? "purple40 font-weight-bold"
        : "sidebar-filter__border-selected";
    } else {
      return this.selectedLounges.find(
        (element: FilterSidebarItem) => element.id === item.id
      )
        ? "purple40 font-weight-bold"
        : "sidebar-filter__border-selected";
    }
  }
  redirectSearchTag(id: number): void {
    this.$router.push({ name: "searchTagId", params: { id: `${id}` } });
  }
  redirectProfileCompany(id: number): void {
    this.$router.push({ name: "profileMarketing", params: { id: `${id}` } });
  }
  clearFilter(type: string): void {
    if (type === "lounge") {
      this.selectedLounges = [];
      this.updateSelectedLounge(this.selectedLounges);
    } else {
      this.selectedEvents = [];
      this.updateSelectedEvent(this.selectedEvents);
    }
  }
  currentLoungeUser(user: any): string {
    return user.role === "PARTICIPANT"
      ? "avatar-green"
      : user.role === "EXECUTIVE"
      ? "avatar-red"
      : user.role === "EQUIPE"
      ? "avatar-black"
      : user.role === "PARTNER"
      ? "avatar-blue"
      : "";
  }
  imgRanking(pos: number): any {
    return pos === 0
      ? require("@/assets/1stplacenew.svg")
      : pos === 1
      ? require("@/assets/2stplace.svg")
      : require("@/assets/3stplace.svg");
  }
  currentUserRanking(item: any): string {
    return item.role === "PARTICIPANT"
      ? "avatar-green"
      : item.role === "EXECUTIVE"
      ? "avatar-red"
      : item.role === "EQUIPE"
      ? "avatar-black"
      : item.role === "PARTNER"
      ? "avatar-blue"
      : "";
  }
  redirectDebate(id: number): void {
    if (this.$route.name === "roundTableItems") {
      this.$router.replace({
        name: "roundTableItems",
        params: { id: String(id) },
      });
      this.changeUpdateDebate(true);
    } else {
      this.$router.push({
        name: "roundTableItems",
        params: { id: String(id) },
      });
    }
  }
  nameLocalDebate(): string {
    return this.debate?.communities?.length > 0
      ? this.debate?.communities?.reduce(
          (string: string, lounge: Event, index: number) => {
            if (index === this.debate?.communities?.length - 1)
              string += String(lounge.name);
            else if (lounge.name) string += lounge.name + ",";
            return string;
          },
          ""
        )
      : this.debate?.events?.length > 0
      ? this.debate?.events?.reduce(
          (string: string, event: Event, index: number) => {
            if (index === this.debate?.events?.length - 1)
              string += String(event.name);
            else if (event.name) string += event.name + ",";
            return string;
          },
          ""
        )
      : `${i18n.t("misc.public")}`;
  }
  @Watch("toggle", { immediate: true }) onItemChanged(): void {
    if (this.$vuetify.breakpoint.mdAndDown) this.drawer = this.toggle;
  }
}
