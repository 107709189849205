
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class TagItem extends Vue {
  @Prop(Array) itemsTag: any;
  @Prop({ default: false }) isClick: boolean;
  @Prop({ default: "black" }) color: string;
  get colorTag(): string {
    return `${this.color}--text`;
  }
  redirectTag(id: number): void {
    if (this.isClick)
      this.$router.push({ name: "searchTagId", params: { id: `${id}` } });
  }
}
