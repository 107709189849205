import App from "@/views/App.vue";

export default [
  {
    path: "/timeline",
    name: "timeline",
    component: App,
    redirect: { name: "timelineHome" },
    children: [
      {
        path: "/timeline/:id?",
        name: "timelineHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewTimeline.vue"),
        meta: {
          displayName: "Timeline - Exclusive Lounge",
          requiresAuth: true,
          name: "Exclusive Lounge",
        },
      },
      {
        path: "/postagem/:id",
        name: "timelinePost",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/CurrentPost.vue"),
        meta: {
          displayName: "Postagem - Exclusive Lounge",
          requiresAuth: true,
          name: "Exclusive Lounge",
        },
      },
      {
        path: "/perfil-fournetwork",
        name: "timelineFourNetworkSection",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/FourNetworkSection.vue"
          ),
        meta: {
          displayName: "Postagem - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/nova-timeline/:id?",
        name: "newTimelineHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewTimeline.vue"),
        meta: {
          displayName: "Timeline - Exclusive Lounge",
          requiresAuth: true,
          name: "Exclusive Lounge",
        },
      },
    ],
  },
];
