
import { Component, Vue, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
@Component
export default class BaseFooter extends Vue {
  @Prop({ default: "white" }) color: string;
  @Prop({ default: "max-content-width" }) limit: string;
  @Prop({ default: false }) withoutSidebar: boolean;
  @State((state) => state.miniSidebarValue) miniSidebarValue!: boolean;
  get classFooter(): string {
    const paddingSidebar =
      this.$vuetify.breakpoint.mdAndDown || this.withoutSidebar
        ? "base-footer__without-sidebar"
        : this.miniSidebarValue
        ? "base-footer__sidebar-mini"
        : "base-footer__sidebar-full";
    return `${paddingSidebar} ${this.color}`;
  }
}
