import API from "../api";
import { Commit } from "vuex";
import {
  ProfileUser,
  Interest,
  InterestSuggest,
  PayloadUsers,
  MiniProfile,
  PayloadBlockProfile,
  UserStatus,
  CardGroupDelete,
  CardDelete,
  PayloadCardGroupPosition,
  PayloadCardPosition,
  PayloadEditCardGroup,
  PayloadNewCardGroup,
  PayloadCurrentCard,
  PayloadEditCard,
  About,
  PayloadUserFollow,
  PayloadFollow,
} from "../types";
import { TermsCase } from "@/types";
export default {
  namespaced: true,
  state: {
    currentUser: {
      name: null,
      interests: [],
    },
    userProfile: {
      name: null,
      interests: [],
      isBlocked: false,
      hasCommonEvent: true,
      hasBlocked: false,
    },
    interestItems: [],
    hobbyItems: [],
    me: {
      id: 0,
      name: "",
      companyName: "",
      occupation: "",
      profileImage: "",
      role: "",
      profileMedalType: null,
      hasMedal: false,
      userTheme: "",
      language: "",
    },
  },
  mutations: {
    setCurrentProfile(state: any, payload: ProfileUser) {
      state.currentUser = payload;
    },
    setInterestItems(state: any, payload: Interest[]) {
      state.interestItems = payload;
    },
    setUserProfile(state: any, payload: ProfileUser) {
      state.userProfile = payload;
    },
    setUserProfileBlockStatus(state: any, payload: boolean) {
      state.userProfile.isBlocked = payload;
    },
    setMe(state: any, payload: MiniProfile) {
      state.me = payload;
    },
    setHobbyItems(state: any, payload: Interest[]) {
      state.hobbyItems = payload;
    },
  },
  actions: {
    async requestCurrentProfile({
      commit,
    }: {
      commit: Commit;
    }): Promise<ProfileUser> {
      const { data } = await API.getCurrentProfile();
      commit("setCurrentProfile", data);
      return data;
    },
    async requestEditProfile(
      {
        commit,
      }: {
        commit: Commit;
      },
      payload: any
    ): Promise<ProfileUser> {
      const { data } = await API.putCurrentProfile(payload);
      commit("setCurrentProfile", data);
      return data;
    },
    async requestInterestItems(
      {
        commit,
      }: {
        commit: Commit;
      },
      payload: string
    ): Promise<Array<Interest>> {
      const { data } = await API.getInterest(payload);
      const mutation =
        payload === "?isHobby=true" ? "setHobbyItems" : "setInterestItems";
      commit(mutation, data);
      return data;
    },
    async requestNewInterest(
      context: any,
      payload: InterestSuggest
    ): Promise<any> {
      const { data } = await API.postInterest(payload);
      return data;
    },
    async requestUsersProfile(
      context: any,
      payload: PayloadUsers
    ): Promise<MiniProfile[]> {
      const { data } = await API.getUsers(payload);
      return data;
    },
    async requestMe({ commit }: { commit: Commit }): Promise<MiniProfile> {
      const { data } = await API.getMe();
      commit("setMe", data);
      return data;
    },
    async requestUserProfile(
      {
        commit,
      }: {
        commit: Commit;
      },
      payload: string
    ): Promise<ProfileUser> {
      const { data } = await API.getUserProfile(payload);
      commit("setUserProfile", data);
      return data;
    },
    async requestUploadProfileImage(context: any, payload: any): Promise<any> {
      const { data } = await API.putProfileImage(payload);
      return data;
    },
    async requestBlockProfile(
      {
        commit,
      }: {
        commit: Commit;
      },
      payload: PayloadBlockProfile
    ): Promise<any> {
      const { data } = await API.postBlockProfile(payload);
      commit("setUserProfileBlockStatus", true);
      return data;
    },
    async requestUnblockProfile(
      {
        commit,
      }: {
        commit: Commit;
      },
      payload: PayloadBlockProfile
    ): Promise<any> {
      const { data } = await API.postUnblockProfile(payload);
      commit("setUserProfileBlockStatus", false);
      return data;
    },
    async requestMiniProfileUser(
      context: any,
      payload: string
    ): Promise<MiniProfile> {
      const { data } = await API.getUser(payload);
      return data;
    },
    async requestUserStatus(
      context: any,
      payload: string
    ): Promise<UserStatus> {
      const { data } = await API.getUserStatus(payload);
      return data;
    },
    async requestUpdateTheme(context: any, payload: string): Promise<any> {
      const { data } = await API.putProfileTheme(payload);
      return data;
    },
    async requestCardGroups(): Promise<MiniProfile> {
      const { data } = await API.getCardGroups();
      return data;
    },
    async requestDeleteCardGroup(
      context: any,
      payload: CardGroupDelete
    ): Promise<any> {
      const { data } = await API.deleteCardGroup(payload);
      return data;
    },
    async requestDeleteCard(context: any, payload: CardDelete): Promise<any> {
      const { data } = await API.deleteCard(payload);
      return data;
    },
    async requestUpdateCardGroupPosition(
      context: any,
      payload: PayloadCardGroupPosition
    ): Promise<any> {
      const { data } = await API.putCardGroupPosition(payload);
      return data;
    },
    async requestUpdateCardPosition(
      context: any,
      payload: PayloadCardPosition
    ): Promise<any> {
      const { data } = await API.putCardPosition(payload);
      return data;
    },
    async requestNewCardGroup(
      context: any,
      payload: PayloadNewCardGroup
    ): Promise<any> {
      const { data } = await API.postCardGroup(payload);
      return data;
    },
    async requestEditCardGroup(
      context: any,
      payload: PayloadEditCardGroup
    ): Promise<any> {
      const { data } = await API.putCardGroup(payload);
      return data;
    },
    async requestCurrentCard(
      context: any,
      payload: PayloadCurrentCard
    ): Promise<any> {
      const { data } = await API.getCurrentCard(payload);
      return data;
    },
    async requestNewCard(context: any, payload: PayloadEditCard): Promise<any> {
      const { data } = await API.postCard(payload);
      return data;
    },
    async requestEditCard(
      context: any,
      payload: PayloadEditCard
    ): Promise<any> {
      const { data } = await API.putCard(payload);
      return data;
    },
    async requestEditAbout(context: any, payload: string): Promise<About> {
      const { data } = await API.putAbout(payload);
      return data;
    },
    async requestAbout(): Promise<About> {
      const { data } = await API.getAbout();
      return data;
    },
    async requestCategories(): Promise<any> {
      const { data } = await API.getCategories();
      return data;
    },
    async requestAllEvents(): Promise<any> {
      const { data } = await API.getAllEvents();
      return data;
    },
    async requestSubmitCase(context: any, payload: any): Promise<any> {
      const { data } = await API.postCase(payload);
      return data;
    },
    async requestTermsCase(): Promise<TermsCase> {
      const { data } = await API.getTermsCase();
      return data;
    },
    async requestInfoMedalsInteraction(): Promise<any> {
      const { data } = await API.getInfoMedalInteraction();
      return data;
    },
    async requestUserFollowing(
      context: any,
      payload: PayloadUserFollow
    ): Promise<any> {
      const { data } = await API.getUserFollwing(payload);
      return data;
    },
    async requestUserFollowers(
      context: any,
      payload: PayloadUserFollow
    ): Promise<any> {
      const { data } = await API.getUserFollowers(payload);
      return data;
    },
    async requestUpdateFollowStatus(
      context: any,
      payload: PayloadFollow
    ): Promise<any> {
      const { data } = await API.putFollow(payload);
      return data;
    },
    async requestAddedToLeads(context: any, payload: any): Promise<any> {
      const { data } = await API.putAddedToLeads(payload);
      return data;
    },
    async requestIsOnline(): Promise<any> {
      const { data } = await API.putIsOnline();
      return data;
    },
    async requestGetMention(context: any, payload: any): Promise<any> {
      const { data } = await API.getMention(payload);
      return data;
    },
    async requestChangeLanguage(context: any, payload: any): Promise<any> {
      const { data } = await API.putLanguage(payload);
      return data;
    },
    async requestStatusFavorite(context: any, payload: any): Promise<any> {
      const { data } = await API.putStatusFavorite(payload);
      return data;
    },
    async requestFavorites(context: any, payload: any): Promise<any> {
      const { data } = await API.getFavorites(payload);
      return data;
    },
    async requestNewEditProfile(
      {
        commit,
      }: {
        commit: Commit;
      },
      payload: any
    ): Promise<ProfileUser> {
      const { data } = await API.putNewCurrentProfile(payload);
      commit("setCurrentProfile", data);
      return data;
    },
  },
  modules: {},
};
