import axios, { AxiosInstance } from "axios";
import { makeAuthorizationHeader } from "./utils";
import defaultAxios from "./axios";
import MainStore from "@/store";
import MainRouter from "@/router";
// import { TokenService } from "./storage";

const { API_URL } = defaultAxios;

export let api: AxiosInstance;

export const initialize = (token: string): void => {
  const instance = axios.create({
    baseURL: API_URL,
    headers: makeAuthorizationHeader(token),
  });

  api = instance;
  api.interceptors.response.use(
    (config) => config,
    async (error) => {
      if (error.response?.status === 401) {
        MainStore.dispatch("requestLogout");
        MainRouter.push({ name: "loginPage" });
      }
      throw error;
    }
  );
};
